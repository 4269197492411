.manage-users-page {
  position: relative;
  width: 100%;
}
.manage-users-page .header {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  min-height: 104px;
}
.manage-users-page .user-actions .invite-user-btn-icon {
  margin-right: 8px;
}
.manage-users-page .user-table .roles {
  display: flex;
  flex-wrap: wrap;
}
.manage-users-page .user-table .roles .role {
  margin: 4px !important;
}
.manage-users-page .user-table .roles .role:first-child {
  margin-left: 0 !important;
}
.manage-users-page .user-table .roles .pending {
  opacity: 0.5;
}
