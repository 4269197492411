.loading-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.loading-indicator .spinner {
  margin-bottom: 16px;
}
.loading-indicator .text {
  text-align: center;
}
