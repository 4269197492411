body {
  font-family: 'Roboto', serif;
  font-size: 0.875rem;
  /* 14sp */
  line-height: 1.25rem;
  /* 20sp */
  font-weight: 400;
  letter-spacing: 0.04em;
}
h1,
.display-4 {
  font-size: 7rem;
  /* 112sp */
  line-height: 7rem;
  /* 112sp */
  font-weight: 300;
  letter-spacing: -0.04em;
  margin: -1rem 0 3.5rem -0.085em;
  /* -16sp 0 56sp -.085em */
}
h2,
.display-3 {
  font-size: 3.5rem;
  /* 56px */
  line-height: 3.5rem;
  /* 56px */
  font-weight: 400;
  letter-spacing: -0.02em;
  margin: -8px 0 64px -0.07em;
}
h3,
.display-2 {
  font-size: 2.813rem;
  /* 45px */
  line-height: 3rem;
  /* 48px */
  font-weight: 400;
  letter-spacing: normal;
  margin: -0.5rem 0 4rem -0.07em;
  /* -8sp 0 64sp -.07em */
}
h4,
.display-1 {
  font-size: 2.125rem;
  /* 34sp */
  line-height: 2.5rem;
  /* 40sp */
  font-weight: 400;
  letter-spacing: normal;
  margin: -0.5rem 0 4rem -0.07em;
  /* -8sp 0 64sp -.07em */
}
h4.medium,
.display-1.medium {
  margin-bottom: 3rem;
}
h4.short,
.display-1.short {
  margin-bottom: 1rem;
}
h5,
.headline {
  font-size: 1.5rem;
  /* 24sp */
  line-height: 2rem;
  /* 32sp */
  font-weight: 400;
  letter-spacing: normal;
  margin: -0.5rem 0 1rem -0.06em;
  /* -8sp 0 16sp -.06em */
}
h6,
.title {
  font-size: 1.25rem;
  /* 20sp */
  line-height: 2rem;
  /* 32sp */
  font-weight: 500;
  letter-spacing: 0.02em;
  margin: -0.5rem 0 1rem -0.05em;
  /* -8sp 0 16sp -.05em */
}
.subheading-2 {
  font-size: 1rem;
  /* 16sp */
  line-height: 1.75rem;
  /* 28sp */
  font-weight: 400;
  letter-spacing: 0.04em;
  margin: -0.5rem 0 1rem -0.06em;
  /* -8sp 0 16sp -.06em */
}
.subheading,
.subheading-1 {
  font-size: 0.938rem;
  /* 15sp */
  line-height: 1.5rem;
  /* 24sp */
  font-weight: 400;
  letter-spacing: 0.04em;
  margin: -0.313rem 0 0.813rem -0.06em;
  /* -5sp 0 13sp -.06em */
}
.body-2 {
  font-size: 0.875rem;
  /* 14sp */
  line-height: 1.5rem;
  /* 24sp */
  font-weight: 500;
  letter-spacing: 0.04em;
  margin: -0.25rem 0 0.75rem 0;
  /* -4sp 0 12sp 0 */
}
.body-1 {
  font-size: 0.875rem;
  /* 14sp */
  line-height: 1.25rem;
  /* 20sp */
  font-weight: 400;
  letter-spacing: 0.04em;
  margin: -0.25rem 0 0.75rem 0;
  /* -4sp 0 12sp 0 */
}
.caption {
  font-size: 0.75rem;
  /* 12sp */
  line-height: 1.25rem;
  /* 20sp */
  font-weight: 400;
  letter-spacing: 0.08em;
  margin: -0.5rem 0 1rem -0.04em;
  /* -8sp 0 16sp -.04em */
}
p {
  margin: -0.25rem 0 0.75rem 0;
  /* -4sp 0 12sp 0 */
}
form .actions {
  margin-top: 24px;
}
form .actions.actions-right {
  text-align: right;
}
form .divider {
  margin: 24px 0 !important;
}
body *:focus {
  outline: none;
}
html,
body,
#root {
  height: 100%;
}
.clickable:hover {
  cursor: pointer;
}
.inline-btn-group {
  display: flex;
  flex-wrap: nowrap;
}
.inline-btn-group > * {
  flex: 1 1 auto;
}
.inline-btn-group > * + * {
  margin-left: 8px;
}
.default-layout {
  padding: 32px 24px;
}
.admin-layout {
  display: flex;
  flex-direction: column;
}
.admin-layout .main {
  flex: 1 1;
  display: flex;
  padding: 32px 24px;
}
h5.MuiTypography-h5 {
  font-weight: 500;
}
.centered-panel-layout {
  background: radial-gradient(at top, #2f353a, #000000) no-repeat;
  width: 100vw;
  height: 100vh;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
}
.centered-panel-layout h1 {
  color: white;
  text-align: center;
  margin-bottom: 32px;
}
.centered-panel-layout > div {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.centered-panel-layout .centered-panel {
  padding: 16px 24px 24px 24px;
  flex: 1 1 auto;
  border-radius: 0 !important;
}
.centered-panel-layout .centered-panel .lead {
  text-align: center;
}
@media only screen and (min-width: 600px) {
  .centered-panel-layout {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .centered-panel-layout > div {
    flex: 1 1 auto;
    display: block;
  }
  .centered-panel-layout .centered-panel {
    width: 480px;
    height: auto;
    border-radius: 2px !important;
  }
}
.unstyled-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
.resizeable-textarea textarea,
textarea.resizeable-textarea {
  resize: both;
}
.form-error .fr-box.fr-basic.fr-top .fr-wrapper {
  box-shadow: 0 1px 3px #ff0000, 0 1px 1px 1px rgba(0, 0, 0, 0.16);
}
